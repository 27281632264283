// Contants
import { ADDONS } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonsMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { get } from 'lodash'

export default {
  name: 'WalletPayPalAccount',
  components: { FormButtons },
  mixins: [addonsMixin, formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        paypalAccount: null
      },
      formFieldsValidations: {
        paypalAccount: {
          required: 'Campo obligatorio'
        }
      }
    }
  },
  computed: {
    ...mapGetters('company', ['companyData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapActions('company', ['updateConfigs']),
    /**
     * Obtengo todos los datos necesarios para el formulario
     */
    getEveryNeededData() {
      // Inicializa datos
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      const companyConfig = get(this.companyAddonsSetupByUser, ADDONS.company, {})
      // Campos formulario
      this.formFields = {
        paypalAccount: companyConfig.paypalAccount
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos configuraciones
      await this.updateConfigs({
        id: this.companyData.id,
        addOns: [
          {
            id: ADDONS.company,
            configFields: {
              ...this.formFields
            }
          }
        ]
      })

      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      paypalAccount: {
        required
      }
    }
  }
}
